import React, {  useState,useEffect } from "react";
import LayoutNew from "../components/LayoutNew";
import { Helmet } from "react-helmet";
import {productCatalogS3Bucket} from "../config";

import HaveQuestion from "./new-pages/common/HaveQuestion";
import RatingReviewNeighborinNeed from "./new-pages/common/RatingReviewNeighborinNeed";
import MessageModal from "./new-pages/common/MessageModal";
import bubbleIcon from "../../static/images/speech-bubble.png";
import herdhelpingtheherd from "../../static/images/herdhelpingtheherd.png";
import handsIn from "../../static/images/handsIn.png";
import graphphoto from "../../static/images/neighborinneedupdate.png";
import tv3Icon2 from "../../static/images/tv-sec-icon2.png"; 
import tv3Icon3 from "../../static/images/tv-sec-icon3.png";
import CommonQuestionNeighborinNeed from "./new-pages/common/CommonQuestionNeighborinNeed";
import axios from "axios";
import {calculateYearlyPricePerMonth} from "../utils";
import LastSection from "./new-pages/common/LastSection";



const FrontTVPage = () => {
    const ignoreList = ["Add 50hrs of DVR", "Pick 5 Channels", "Single Channels"]
    const [showModal, setShowModal] = useState(false);
    const [tabType, setTabType] = React.useState(false);
    const [messagePopup, setMessagePopup] = React.useState(false);
    const [includedChannles, setIncludedChannles] = React.useState([]);
    /**
     * get included channels for popup
     */

    const getChannelImageUrls = (channels: any) => {
        setIncludedChannles(channels.filter(obj => obj.status === "ACTIVE").map(obj => obj.image_url))
        setShowModal(true);
    }

    const [plans, setPlans] = useState([]);

    const [addons, setAddons] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const plansResponse = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/tv/plans.json"
                );
                const addonsResponse = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/tv/addons.json"
                );
                setPlans(plansResponse.data);
                setAddons(addonsResponse.data);
            } catch (err) {
                console.error("Failed to fetch internet plans:", err);
            }
        };
        fetchData();
    },[])

    return (
        <LayoutNew>


            <Helmet>
                <title>TV – Purple Cow Internet 💜🐄</title>
            </Helmet>
            <section className="tv-section4">
                <div className="container mx-auto px-4 text-center" >
                    <h2 className="h2 text-secondary text-center ">The herd helping the herd</h2>
                    <img src={handsIn} alt="TV" class="mx-auto"/>
                </div>  
            </section>
            <div className="container mx-auto">
                <section className="tv-section2 gray-box">
                    <div className="">
                        <div className="section2-text text-center">
                            <h2 className="h3">What is Purple Cow's Neighbor in Need program? </h2>
                            <p className="p2">We all know times are tough, and we want to help make a difference. That’s why we’re partnering with the herd to create positive change. We're asking members to donate just $2 a month, and Purple Cow will match each donation dollar for dollar.</p>
                            <p className="p2">These funds will go directly to people who are facing financial hardship to keep their internet connected as they work to get back on their hooves.</p>
                        </div>

                    </div>
                </section>
            </div>
            
            <section className="tv-section4">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center ">Where are we at?</h2>
                </div>
                <div className="container mx-auto px-10 text-center">
                    <p className="p2">Our goal is to maintain complete transparency with the Neighbor in Need program, and we will provide regular updates on the funds contributed and distributed.</p>
                </div> 
                <br></br> 
                <div className="container mx-auto px-4 text-center" >
                    
                    <img src={graphphoto} alt="TV" class="mx-auto"/>
                </div>           
                
                
            </section>

            <RatingReviewNeighborinNeed
                    title="The herd helping the herd"
                    
                />


            

            <CommonQuestionNeighborinNeed/>

    <HaveQuestion closepopup={setMessagePopup}/>
   

            <div className="contact-us-parent">
                <div
                    className="contact-click-box"
                    onClick={() => {
                        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                        if (isMobile) {
                            // Open the text message service on mobile devices
                            window.location.href = "sms:1-902-800-2660";
                        } else {
                            // Open the existing popup on other devices
                            setMessagePopup(true);
                        }
                    }}
                >
                    <img src={bubbleIcon} alt="bubble" className="bubble-icon" />
                </div>
            </div>
            {
                messagePopup == true ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null
            }
            {
                showModal ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div
                                    className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <button
                                        className="ml-auto mt-0 bg-transparent border-0 text-white leading-none outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                    <span
                                        className="bg-transparent opacity-25 text-black h-6 w-6 text-4xl block outline-none focus:outline-none mr-2">
                                        ×
                                    </span>
                                    </button>
                                    <div className="text-center">
                                        <h4 className="text-1xl text-gray-600">Included Channels</h4>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="#47525E"
                                            className="w-6 h-6 m-auto cursor-pointer"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                            />
                                        </svg>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 chennel-modal-parent">
                                        <div className="chennels-box">
                                            {includedChannles.map((item, index) => {
                                                return (
                                                    <>
                                                        <div className="chennels-box__icon" key={index + 's'}>
                                                            <img src={item}></img>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null
            }
        </LayoutNew>
    )
}

export default FrontTVPage
 
