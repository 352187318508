import { Link } from "gatsby"
import React from "react"
import Facebook from "@svg-icons/fa-brands/facebook-f.svg"
import Instagram from "@svg-icons/fa-brands/instagram.svg"
import MessageModal from "../pages/new-pages/common/MessageModal";
import Youtube from "@svg-icons/fa-brands/youtube.svg"

const FooterNew = () => {
    const [messagePopup, setMessagePopup] = React.useState(false);

    return (
      <footer className="footer-new">
          <div className="footer-content">
              <div className="footer-column">
                  <Link to="/internet/" className="footer-link">Internet</Link>
                  <Link to="/tv/" className="footer-link">TV</Link>
                  <Link to="/home-phone/" className="footer-link">Phone</Link>
              </div>
              <div className="footer-column">
                  <button className="footer-link" onClick={() => setMessagePopup(true)}>Message Us</button>
                  <a href="https://purplecowinternet.chargebeeportal.com" className="footer-link">My Account</a>
                  <Link to="/thoughts" className="footer-link">Thoughts</Link>
                  <Link to="/sponsorships" className="footer-link">Sponsorships</Link>
                  <Link to="/terms-of-use" className="footer-link">Terms of Service</Link>
                  <Link to="/neighbor-in-need" className="footer-link">Neighbor in Need</Link>
              </div>
              <div className="footer-column contact-info">
                <p>
                    Text us at{" "}
                    <a
                        href="#"
                        className="footer-link"
                        onClick={(e) => {
                        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                        if (isMobile) {
                            e.preventDefault(); // Prevent the default anchor click behavior
                            window.location.href = "sms:1-902-800-2660?body=Hey%20Purple%20Cow%20I%20have%20a%20question%20";
                        } else {
                            e.preventDefault(); // Prevent link behavior on desktops
                        }
                        }}
                    >
                        1-902-800-2660
                    </a>{" "}
                    for a
                    </p>
                  <p>customer representative between:</p><br/>
                  <p>Monday - Friday | 8:00am - 11:00pm</p>
                  <p>Saturday - Sunday | 9:00am - 10:00pm</p>
              </div>
          </div>
          <div className="social-media">
              <a href="//web.facebook.com/purplecowinternet" className="social-icon">
                  <Facebook className="icon" />
              </a>
              <a href="//www.instagram.com/purplecowinternet/" className="social-icon">
                  <Instagram className="icon" />
              </a>
              <a href="//www.youtube.com/purplecowinternet/" className="social-icon">
                  <Youtube className="icon" />
              </a>
          </div>
          <p className="footer-reminder">Follow us on social media!</p>
          <p className="footer-reminder">Remember You're Awesome</p>
          {messagePopup ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
      </footer>
    );
}

export default FooterNew
