import React, { useEffect } from "react";
import Star from "../../../../static/images/star.png";

const RatingReview = (props: any) => {
    const { title, subtitle } = props;
   
  useEffect(() => {
    const scrollContainer = document.querySelector(".review-scroll");
    
    if (scrollContainer) {
      const clonedContent = scrollContainer.innerHTML;
      scrollContainer.innerHTML += clonedContent; // Duplicate content for seamless scrolling
    }
  }, []);
  
  return (
    <div className="review-section">
        <section className="internet-section4">  
      <div className="review-header">
      <h2 className="h2 text-secondary text-center">{title}</h2>
      <p className="p2 text-black text-center mb-12">{subtitle}</p>
        <div className="large-star-row">
          {[...Array(5)].map((_, index) => (
            <img
              src={Star}
              alt="Star"
              className="large-star-icon"
              key={`large-star-${index}`}
            />
          ))}
        </div>
      </div>
      <div className="review-container">
        <div className="review-scroll">
          <div className="review-card">
            <span className="review-label">Actual Customer</span>
            <h4 className="customer-name">Jamie, Halifax, Nova Scotia</h4>
            <div className="star-row">
              {[...Array(5)].map((_, index) => (
                <img
                  src={Star}
                  alt="Star"
                  className="star-icon"
                  key={`adam-star-${index}`}
                />
              ))}
            </div>
            <p>
              From the placing the order to the delivery to the installation instructions to the
              assistance connecting our printer, 5 Star service. Would not hesitate to recommend.
              Great service Bradley, Sebastian and Darren. 👍. Thank you!
            </p>
          </div>
          <div className="review-card">
            <span className="review-label">Purple Cow Customer</span>
            <h4 className="customer-name">Shane, Charlottetown, Prince Edward Island</h4>
            <div className="star-row">
              {[...Array(5)].map((_, index) => (
                <img
                  src={Star}
                  alt="Star"
                  className="star-icon"
                  key={`shane-star-${index}`}
                />
              ))}
            </div>
            <p>
              Rocky start (a defective modem) and it took some effort to figure out what was
              wrong. But the customer service I received from Rob can’t be compared!
            </p>
          </div>
          <div className="review-card">
            <span className="review-label">My Mom</span>
            <h4 className="customer-name">Darlene, Mahone Bay, Nova Scotia</h4>
            <div className="star-row">
              {[...Array(5)].map((_, index) => (
                <img
                  src={Star}
                  alt="Star"
                  className="star-icon"
                  key={`leo-star-${index}`}
                />
              ))}
            </div>
            <p>
              Really nothing negative to say. Very reliable service, easy and quick installation,
              reliable and fair internet speed, in short no matter the option or choice you make,
              there will be no surprises.
            </p>
          </div>
          <div className="review-card">
            <span className="review-label">Actual Herd Member</span>
            <h4 className="customer-name">Marianne, Charlottetown, Prince Edward Island</h4>
            <div className="star-row">
              {[...Array(5)].map((_, index) => (
                <img
                  src={Star}
                  alt="Star"
                  className="star-icon"
                  key={`marianne-star-${index}`}
                />
              ))}
            </div>
            <p>
              Super easy set up with quick support if needed. Strong, fast and cheap service!
              Big companies don’t need your business, support your local.
            </p>
          </div>
          <div className="review-card">
            <span className="review-label">Real Person</span>
            <h4 className="customer-name">Monica, Dartmouth, Nova Scotia</h4>
            <div className="star-row">
              {[...Array(5)].map((_, index) => (
                <img
                  src={Star}
                  alt="Star"
                  className="star-icon"
                  key={`monica-star-${index}`}
                />
              ))}
            </div>
            <p>
              Everything that I hoped would fulfill my needs is taken care of. The whole
              package!! The next service you want me to do I'm going to pass. Just the people
              that can count wrinkles would be involved and happy.
            </p>
          </div>
          <div className="review-card">
            <span className="review-label">Actual Herd Member</span>
            <h4 className="customer-name">Sheena, Dartmouth, Nova Scotia</h4>
            <div className="star-row">
              {[...Array(5)].map((_, index) => (
                <img
                  src={Star}
                  alt="Star"
                  className="star-icon"
                  key={`monica-star-${index}`}
                />
              ))}
            </div>
            <p>
            Amazing customer service and support, using a fire stick for the first time and the lowest internet speed has enough strength to enable great service. Can’t believe the cost saving also, switch your provider to purple cow, you won’t regret it!!
            </p>
          </div>
          <div className="review-card">
            <span className="review-label">A real super star</span>
            <h4 className="customer-name">Deena, Dartmouth, Nova Scotia</h4>
            <div className="star-row">
              {[...Array(5)].map((_, index) => (
                <img
                  src={Star}
                  alt="Star"
                  className="star-icon"
                  key={`monica-star-${index}`}
                />
              ))}
            </div>
            <p>
            Easy peasy. All the set up was done over text and it couldn't have been easier. The price is right and the internet is as resalable as it gets on this side of the world.
            </p>
          </div>
        </div>
      </div>
      </section>  
    </div>
  );
};

export default RatingReview;

