import React, { useEffect, useState } from "react";
import LayoutNew from "../components/LayoutNew";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Link } from "gatsby"
import wifirouter from "../../static/images/wifi-router.png";
import guaranteed from "../../static/images/guaranteed.png";
import {splitDescription} from "../utils";
import HaveQuestion from "./new-pages/common/HaveQuestion";
import MessageModal from "./new-pages/common/MessageModal";
import bubbleIcon from "../../static/images/speech-bubble.png";
import superIcon1 from "../../static/images/super-icon1.png";
import superIcon2 from "../../static/images/super-icon2.png";
import RatingReviewInternet from "./new-pages/common/RatingReviewInternet";
import CommonQuestionInternet from "./new-pages/common/CommonQuestionInternet";
import { productCatalogS3Bucket} from "../config";
import LastSection from "./new-pages/common/LastSection";


const FrontInternetPage = () => {
    const [tabType, setTabType] = useState(false);
    const [messagePopup, setMessagePopup] = useState(false);
    const [plans, setPlans] = useState([{
        id: 0,
        api_name: "Loading...",
        description: "\u2714\ufe0fLoading...",
        speed: "Loading...",
        provider: "Loading...",
        billing: [
            {
                monthly: {
                    api_name: "Loading...",
                    display_name: "Internet",
                    invoice_name: "Internet",
                    price: 60.00
                }
            }
        ],
        addons: [],
        resources: [],
        status: "ACTIVE"
    }]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/internet/plans.json"
                );
                setPlans(response.data);
            } catch (err) {
                console.error("Failed to fetch internet plans:", err);
            }
        };
        fetchData();
    },[])

    return (
        <LayoutNew>
            <Helmet>
                <title>Internet – Purple Cow Internet 💜🐄</title>
            </Helmet>

            <section className="tv-section4">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">We keep the pricing simple</h2>
                    <p className="p2 text-black text-center mb-8">& doesn't go up after a year!</p>
                    
                    <div className="selectPlan addon-plan-1">
                        {plans.map((plan, index) => (
                            plan.status !== "ACTIVE" ? null : (
                                <div 
                                    key={index} 
                                    className={`selectPlan__optional ${index === 0 ? 'most-popular' : ''}`}
                                >
                                    {index === 0 && (
                                        <div className="most-popular-badge">Most Popular</div>
                                    )}
                                    <label className="selectPlan__box phone-page">
                                        <input
                                            type="checkbox"
                                            name="selectPlan__"
                                            checked={false}
                                            readOnly
                                        />
                                        <div className="selectPlan__box-inner">
                                            <h3 className="h3">{plan.name}</h3>
                                            <h2 className="h2"
                                                dangerouslySetInnerHTML={{
                                                    __html: `$${plan.billing[0].monthly.price} <sup>/ month</sup>`
                                                }}
                                            ></h2>
                                            
                                            <ul className="plan-list">
                                                {splitDescription(plan.description).map((line, idx) => (
                                                    <li key={idx}>{line}</li>
                                                ))}
                                            </ul>
                                            <Link className="btn-bg-new mx-auto mt-8" to="/join-the-herd">Select Plan</Link>
                                        </div>
                                    </label>
                                </div>
                            )
                        ))}
                    </div>
                    
                    <p className="p2 bottom">One-time setup and shipping of $55.</p>
                </div>
            </section>

            <section className="internet-section2">
                <div className="container mx-auto px-4 bg-transparent">
                    <h2 className="h2">It’s super-reliable</h2>
                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="relative">
                            <div className="gray-round">
                                <img src={superIcon1} alt="internet" className="internet-sec2-icon"/>
                            </div>
                            <h4 className="h4">Gigabit Speeds</h4>
                            <p className="p2 text-center">Watch, download and play as much as you want</p>
                        </div>
                        <div className="relative">
                            <div className="gray-round">
                                <span>99.99%</span>
                            </div>
                            <h4 className="h4">Extremely Reliable</h4>
                            <p className="p2 text-center">With a 99.99% uptime we are one of the best in the industry</p>
                        </div>
                        <div className="relative">
                            <div className="gray-round">
                                <img src={superIcon2} alt="internet" className="internet-sec2-icon"/>
                            </div>
                            <h4 className="h4">Customer Service</h4>
                            <p className="p2 text-center">Ranked #1 in Canada by google customer reviews</p>
                        </div>
                    </div>
                </div>
            </section>
            <br></br>
            <br></br>
         
        <section className="tv-section9">
            <div className="container mx-auto px-4">
            <h2 className="h2 text-secondary text-center">You get great Wi-Fi—for free!</h2>
                <div className="max-width1000">
                    <div className="grid  sm:grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="">
                            <img src={wifirouter} alt="TV" className="" />
                        </div>
                        <div className="section9-text">
                            <h3 className="h3">The incredibly smart Wi-Fi modem</h3>
                            <p className="p2">Our Wi-Fi modem, which also plays the role of a router, will impress you with its amazing technical capabilities. Its band-steering function, for example, makes for smooth web surfing on any device.</p>
                            <p className="p2">With only one box to plug in, you’ll have a clean, streamlined look that’s perfect for placing this router in any room of the home.</p>
                            <Link className="btn-bg-new mx-auto mt-8"  to="/join-the-herd">Check my eligibility</Link>
                        </div>

                    </div>
                </div>
            </div>
        </section> 
        <section className="internet-section2">
                <div className="container mx-auto px-4 bg-transparent">
                    <h2 className="h2">Love your switch, or it’s free!</h2>
                    <p className="p2 text-center">We stand by the quality of our service. If you’re not absolutely in love with your Purple Cow Internet experience after the first month and you want to leave the herd, we’ll refund your money 100%. No hassle, no fine print—just great service, guaranteed.</p>
                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-4">
                        <div className="relative">
                            <div className="">
                                <img src={guaranteed} alt="guaranteed" className="center" />
                                <Link className="btn-bg-new mx-auto mt-8"  to="/join-the-herd">Check my eligibility</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <RatingReviewInternet
                title="What your friends have to say"
                subtitle="These are actual reviews from Google"
            />

            <CommonQuestionInternet/>

            <HaveQuestion closepopup={setMessagePopup}/>

            <LastSection title="Time to jump the fence?" />
            <div className="contact-us-parent">
                <div
                    className="contact-click-box"
                    onClick={(e) => {
                        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                        if (isMobile) {
                            e.preventDefault(); // Prevent the default anchor click behavior
                            window.location.href = "sms:1-902-800-2660?body=Hey%20Purple%20Cow%20I%20have%20a%20question.%20";
                        } else {
                            // Open the existing popup on other devices
                            setMessagePopup(true);
                        }
                    }}
                >
                    <img src={bubbleIcon} alt="bubble" className="bubble-icon" />
                </div>
            </div>
            {messagePopup ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
        </LayoutNew>
    )
}

export default FrontInternetPage