import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>2024: A Year of Growth, Connection, and Innovation 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
       
        
        <StaticImage
          layout="constrained"
          src="images/Bulkdeals.png"
          alt="Purple Cow Internet - 2024 recap"
          /> 

    
<header>
        <h1>Why Bulk Deals Are Bad for Apartment Residents—and Halifax’s Internet Future</h1>
    </header>

    <section>
        <p>Bulk deals between Internet Service Providers (ISPs) and apartment building owners might seem like a good idea on the surface, but they come with significant downsides for tenants and the local community. As Halifax grows, it’s critical to recognize these challenges and stand up against practices that limit tenant choice, fair competition, and even service reliability.</p>
    </section>

    <section>
        <h2>What Are Bulk Deals?</h2>
        <p>A bulk deal happens when an ISP offers a discounted rate—sometimes as low as $35—for internet and related services to an entire apartment building. Building owners often include these costs in the rent, so tenants don’t see a separate internet bill or, in some cases, resell the services for a profit. On paper, this might seem convenient—but the reality is far less appealing.</p>
        <p>These agreements often last anywhere from 3 to 10 years. During this time, competing ISPs are effectively locked out from wiring the building, as it’s financially unviable to invest in infrastructure without being able to attract tenants. Once the agreement expires, the ISP that signed the bulk deal has a monopoly over the building, enabling them to raise prices or reduce service quality, which we have seen many times here in Halifax.</p>
    </section>

    <section>
        <h2>The Bigger Issue: Reduced Choice and Increased Vulnerability</h2>
        <p>Tenants in buildings with bulk deals face several serious drawbacks:</p>
        <ul>
            <li><strong>No Freedom to Choose:</strong> Tenants are unable to support ISPs with better customer service, lower costs, or values they align with.</li>
            <li><strong>No Redundancy in Emergencies:</strong> Bulk deals eliminate competition, which also means no redundancy of providers. In the Maritimes, where hurricanes and severe weather are becoming more common, this lack of redundancy leaves buildings vulnerable. If the ISP under contract experiences a weather-related outage, the entire building could be left offline for extended periods.</li>
            <li><strong>Higher Costs in the Long Run:</strong> After the bulk agreement ends, the ISP that wired the building can raise prices, knowing tenants have no alternative.</li>
            <li><strong>Lower Service Quality:</strong> Without competition, there’s less incentive to innovate, maintain high-quality service, or address tenant concerns.</li>
        </ul>
    </section>

    <section>
        <h2>Toronto’s Warning Sign</h2>
        <p>In Toronto, nearly 50% of apartment buildings lack internet choice due to bulk deals and exclusivity arrangements. Tenants in these buildings are stuck with a single provider, often paying more for subpar service.</p>
        <p>Halifax is growing quickly, and this Toronto trend is starting to pop up in several new buildings, including landmark developments like Richmond Yards to Toronto-based Hazelview Properties’ building on Dunbrack St, which exclusively has one internet service provider offering services. The two big players in our market are signing deals that lock buildings into long-term monopolies. Without intervention, Halifax risks following the same path as Toronto.</p>
    </section>

    <section>
        <h2>The Role of the CRTC</h2>
        <p>Even the Canadian Radio-television and Telecommunications Commission (CRTC) recognizes the harm caused by bulk deals. In a recent case, tenants and smaller ISPs pushed back against Rogers’ bulk agreements, arguing that these deals unfairly limit competition and choice. The CRTC acknowledged these concerns and is working to address anti-competitive practices, a move that could benefit tenants and smaller ISPs across Canada.</p>
    </section>

    <section>
        <h2>Building Owners Hold the Key</h2>
        <p>Building owners play a crucial role in ensuring their tenants have choice and fair competition. By allowing multiple ISPs to wire their buildings, owners can create a competitive environment that:</p>
        <ul>
            <li>Provides redundancy, reducing the risk of extended outages.</li>
            <li>Keeps internet costs low.</li>
            <li>Ensures better service quality.</li>
            <li>Offers tenants the freedom to choose their provider.</li>
        </ul>
        <p>Bulk deals might seem like a short-term win for building owners, but they ultimately hurt tenants, stifle competition, and limit innovation.</p>
    </section>

    <section>
        <h2>A Better Path Forward for Halifax</h2>
        <p>To prevent Halifax from becoming another Toronto, building owners need to prioritize tenant choice. Ensuring open access to buildings during construction allows multiple ISPs to compete, creating a healthier, more competitive market for residents.</p>
        <p>At Purple Cow, we’re committed to driving down costs, delivering high-quality internet, and standing against restrictive agreements. We believe in fair competition and the power of choice. Together with building owners, we can make Halifax a beacon of affordable, high-quality internet—one that sets the standard for the rest of Canada.</p>
        <p>If you’re a building owner interested in protecting choice for your tenants, let’s connect. Together, we can ensure a better, more connected future for everyone.</p>
    </section>
    
            
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
