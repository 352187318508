exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-address-tool-tsx": () => import("./../../../src/pages/address-tool.tsx" /* webpackChunkName: "component---src-pages-address-tool-tsx" */),
  "component---src-pages-coax-ports-tsx": () => import("./../../../src/pages/coax-ports.tsx" /* webpackChunkName: "component---src-pages-coax-ports-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-home-phone-setup-tsx": () => import("./../../../src/pages/home-phone/setup.tsx" /* webpackChunkName: "component---src-pages-home-phone-setup-tsx" */),
  "component---src-pages-home-phone-star-codes-tsx": () => import("./../../../src/pages/home-phone/star-codes.tsx" /* webpackChunkName: "component---src-pages-home-phone-star-codes-tsx" */),
  "component---src-pages-home-phone-tsx": () => import("./../../../src/pages/home-phone.tsx" /* webpackChunkName: "component---src-pages-home-phone-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internet-tsx": () => import("./../../../src/pages/internet.tsx" /* webpackChunkName: "component---src-pages-internet-tsx" */),
  "component---src-pages-join-the-herd-tsx": () => import("./../../../src/pages/join-the-herd.tsx" /* webpackChunkName: "component---src-pages-join-the-herd-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-legal-accessibility-tsx": () => import("./../../../src/pages/legal/accessibility.tsx" /* webpackChunkName: "component---src-pages-legal-accessibility-tsx" */),
  "component---src-pages-legal-contest-rules-tsx": () => import("./../../../src/pages/legal/contest-rules.tsx" /* webpackChunkName: "component---src-pages-legal-contest-rules-tsx" */),
  "component---src-pages-neighbor-in-need-tsx": () => import("./../../../src/pages/neighbor-in-need.tsx" /* webpackChunkName: "component---src-pages-neighbor-in-need-tsx" */),
  "component---src-pages-new-pages-addon-plans-component-tsx": () => import("./../../../src/pages/new-pages/AddonPlansComponent.tsx" /* webpackChunkName: "component---src-pages-new-pages-addon-plans-component-tsx" */),
  "component---src-pages-new-pages-address-component-tsx": () => import("./../../../src/pages/new-pages/AddressComponent.tsx" /* webpackChunkName: "component---src-pages-new-pages-address-component-tsx" */),
  "component---src-pages-new-pages-checkout-component-tsx": () => import("./../../../src/pages/new-pages/CheckoutComponent.tsx" /* webpackChunkName: "component---src-pages-new-pages-checkout-component-tsx" */),
  "component---src-pages-new-pages-common-common-onboarding-fa-qs-tsx": () => import("./../../../src/pages/new-pages/common/CommonOnboardingFAQs.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-common-onboarding-fa-qs-tsx" */),
  "component---src-pages-new-pages-common-common-question-home-tsx": () => import("./../../../src/pages/new-pages/common/CommonQuestionHome.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-common-question-home-tsx" */),
  "component---src-pages-new-pages-common-common-question-internet-tsx": () => import("./../../../src/pages/new-pages/common/CommonQuestionInternet.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-common-question-internet-tsx" */),
  "component---src-pages-new-pages-common-common-question-neighborin-need-tsx": () => import("./../../../src/pages/new-pages/common/CommonQuestionNeighborinNeed.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-common-question-neighborin-need-tsx" */),
  "component---src-pages-new-pages-common-common-question-phone-tsx": () => import("./../../../src/pages/new-pages/common/CommonQuestionPhone.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-common-question-phone-tsx" */),
  "component---src-pages-new-pages-common-common-question-tv-tsx": () => import("./../../../src/pages/new-pages/common/CommonQuestionTV.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-common-question-tv-tsx" */),
  "component---src-pages-new-pages-common-common-question-when-setting-up-your-home-phone-tsx": () => import("./../../../src/pages/new-pages/common/CommonQuestionWhenSettingUpYourHomePhone.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-common-question-when-setting-up-your-home-phone-tsx" */),
  "component---src-pages-new-pages-common-common-question-when-setting-up-your-internet-tsx": () => import("./../../../src/pages/new-pages/common/CommonQuestionWhenSettingUpYourInternet.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-common-question-when-setting-up-your-internet-tsx" */),
  "component---src-pages-new-pages-common-common-question-when-setting-up-your-tv-tsx": () => import("./../../../src/pages/new-pages/common/CommonQuestionWhenSettingUpYourTV.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-common-question-when-setting-up-your-tv-tsx" */),
  "component---src-pages-new-pages-common-easy-steps-tsx": () => import("./../../../src/pages/new-pages/common/EasySteps.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-easy-steps-tsx" */),
  "component---src-pages-new-pages-common-have-question-tsx": () => import("./../../../src/pages/new-pages/common/HaveQuestion.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-have-question-tsx" */),
  "component---src-pages-new-pages-common-last-section-tsx": () => import("./../../../src/pages/new-pages/common/LastSection.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-last-section-tsx" */),
  "component---src-pages-new-pages-common-message-modal-tsx": () => import("./../../../src/pages/new-pages/common/MessageModal.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-message-modal-tsx" */),
  "component---src-pages-new-pages-common-rating-review-home-tsx": () => import("./../../../src/pages/new-pages/common/RatingReviewHome.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-rating-review-home-tsx" */),
  "component---src-pages-new-pages-common-rating-review-internet-tsx": () => import("./../../../src/pages/new-pages/common/RatingReviewInternet.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-rating-review-internet-tsx" */),
  "component---src-pages-new-pages-common-rating-review-neighborin-need-tsx": () => import("./../../../src/pages/new-pages/common/RatingReviewNeighborinNeed.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-rating-review-neighborin-need-tsx" */),
  "component---src-pages-new-pages-common-rating-review-tsx": () => import("./../../../src/pages/new-pages/common/RatingReview.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-rating-review-tsx" */),
  "component---src-pages-new-pages-common-service-address-tsx": () => import("./../../../src/pages/new-pages/common/ServiceAddress.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-service-address-tsx" */),
  "component---src-pages-new-pages-common-video-section-internet-tsx": () => import("./../../../src/pages/new-pages/common/VideoSectionInternet.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-video-section-internet-tsx" */),
  "component---src-pages-new-pages-common-video-section-tsx": () => import("./../../../src/pages/new-pages/common/VideoSection.tsx" /* webpackChunkName: "component---src-pages-new-pages-common-video-section-tsx" */),
  "component---src-pages-new-pages-contact-form-component-tsx": () => import("./../../../src/pages/new-pages/ContactFormComponent.tsx" /* webpackChunkName: "component---src-pages-new-pages-contact-form-component-tsx" */),
  "component---src-pages-new-pages-double-check-address-component-tsx": () => import("./../../../src/pages/new-pages/DoubleCheckAddressComponent.tsx" /* webpackChunkName: "component---src-pages-new-pages-double-check-address-component-tsx" */),
  "component---src-pages-new-pages-internet-plans-component-tsx": () => import("./../../../src/pages/new-pages/InternetPlansComponent.tsx" /* webpackChunkName: "component---src-pages-new-pages-internet-plans-component-tsx" */),
  "component---src-pages-new-pages-invalid-address-tsx": () => import("./../../../src/pages/new-pages/InvalidAddress.tsx" /* webpackChunkName: "component---src-pages-new-pages-invalid-address-tsx" */),
  "component---src-pages-new-pages-loader-component-tsx": () => import("./../../../src/pages/new-pages/LoaderComponent.tsx" /* webpackChunkName: "component---src-pages-new-pages-loader-component-tsx" */),
  "component---src-pages-new-pages-menu-component-tsx": () => import("./../../../src/pages/new-pages/MenuComponent.tsx" /* webpackChunkName: "component---src-pages-new-pages-menu-component-tsx" */),
  "component---src-pages-new-pages-payment-success-tsx": () => import("./../../../src/pages/new-pages/PaymentSuccess.tsx" /* webpackChunkName: "component---src-pages-new-pages-payment-success-tsx" */),
  "component---src-pages-new-pages-tv-plans-component-tsx": () => import("./../../../src/pages/new-pages/TvPlansComponent.tsx" /* webpackChunkName: "component---src-pages-new-pages-tv-plans-component-tsx" */),
  "component---src-pages-new-pages-tv-plans-optional-tsx": () => import("./../../../src/pages/new-pages/TvPlansOptional.tsx" /* webpackChunkName: "component---src-pages-new-pages-tv-plans-optional-tsx" */),
  "component---src-pages-new-pages-waiting-list-address-component-tsx": () => import("./../../../src/pages/new-pages/WaitingListAddressComponent.tsx" /* webpackChunkName: "component---src-pages-new-pages-waiting-list-address-component-tsx" */),
  "component---src-pages-onboarding-faqs-tsx": () => import("./../../../src/pages/onboarding-faqs.tsx" /* webpackChunkName: "component---src-pages-onboarding-faqs-tsx" */),
  "component---src-pages-powernumbers-tsx": () => import("./../../../src/pages/powernumbers.tsx" /* webpackChunkName: "component---src-pages-powernumbers-tsx" */),
  "component---src-pages-purplefibre-waiting-list-tsx": () => import("./../../../src/pages/purplefibre/waiting-list.tsx" /* webpackChunkName: "component---src-pages-purplefibre-waiting-list-tsx" */),
  "component---src-pages-service-interruptions-tsx": () => import("./../../../src/pages/service-interruptions.tsx" /* webpackChunkName: "component---src-pages-service-interruptions-tsx" */),
  "component---src-pages-splitters-tsx": () => import("./../../../src/pages/splitters.tsx" /* webpackChunkName: "component---src-pages-splitters-tsx" */),
  "component---src-pages-sponsorships-tsx": () => import("./../../../src/pages/sponsorships.tsx" /* webpackChunkName: "component---src-pages-sponsorships-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-thoughts-10-unique-ways-to-save-money-in-2024-without-giving-up-coffee-tsx": () => import("./../../../src/pages/thoughts/10-Unique-Ways-to-Save-Money-in-2024-Without-Giving-Up-Coffee.tsx" /* webpackChunkName: "component---src-pages-thoughts-10-unique-ways-to-save-money-in-2024-without-giving-up-coffee-tsx" */),
  "component---src-pages-thoughts-2023-personal-annual-report-tsx": () => import("./../../../src/pages/thoughts/2023-Personal-Annual-Report.tsx" /* webpackChunkName: "component---src-pages-thoughts-2023-personal-annual-report-tsx" */),
  "component---src-pages-thoughts-2023-recap-tsx": () => import("./../../../src/pages/thoughts/2023-recap.tsx" /* webpackChunkName: "component---src-pages-thoughts-2023-recap-tsx" */),
  "component---src-pages-thoughts-2024-recap-tsx": () => import("./../../../src/pages/thoughts/2024recap.tsx" /* webpackChunkName: "component---src-pages-thoughts-2024-recap-tsx" */),
  "component---src-pages-thoughts-90-days-since-launching-purple-cow-tv-tsx": () => import("./../../../src/pages/thoughts/90-days-since-launching-purple-cow-tv.tsx" /* webpackChunkName: "component---src-pages-thoughts-90-days-since-launching-purple-cow-tv-tsx" */),
  "component---src-pages-thoughts-back-to-school-with-purple-cow-internet-tsx": () => import("./../../../src/pages/thoughts/Back-To-School-With-Purple-Cow-Internet.tsx" /* webpackChunkName: "component---src-pages-thoughts-back-to-school-with-purple-cow-internet-tsx" */),
  "component---src-pages-thoughts-bluenose-partnership-tsx": () => import("./../../../src/pages/thoughts/Bluenose-Partnership.tsx" /* webpackChunkName: "component---src-pages-thoughts-bluenose-partnership-tsx" */),
  "component---src-pages-thoughts-bulkdeals-tsx": () => import("./../../../src/pages/thoughts/Bulkdeals.tsx" /* webpackChunkName: "component---src-pages-thoughts-bulkdeals-tsx" */),
  "component---src-pages-thoughts-do-i-really-need-to-pay-for-a-gig-tsx": () => import("./../../../src/pages/thoughts/Do-I-really-need-to-pay-for-a-Gig.tsx" /* webpackChunkName: "component---src-pages-thoughts-do-i-really-need-to-pay-for-a-gig-tsx" */),
  "component---src-pages-thoughts-empowering-communities-beyond-connectivity-tsx": () => import("./../../../src/pages/thoughts/Empowering-Communities-Beyond-Connectivity.tsx" /* webpackChunkName: "component---src-pages-thoughts-empowering-communities-beyond-connectivity-tsx" */),
  "component---src-pages-thoughts-finalist-for-business-leader-tsx": () => import("./../../../src/pages/thoughts/Finalist-for-business-leader.tsx" /* webpackChunkName: "component---src-pages-thoughts-finalist-for-business-leader-tsx" */),
  "component---src-pages-thoughts-how-switching-to-purple-cow-internet-can-transform-internet-access-in-nova-scotia-tsx": () => import("./../../../src/pages/thoughts/How-Switching-to-Purple-Cow-Internet-Can-Transform-Internet-Access-in-Nova-Scotia.tsx" /* webpackChunkName: "component---src-pages-thoughts-how-switching-to-purple-cow-internet-can-transform-internet-access-in-nova-scotia-tsx" */),
  "component---src-pages-thoughts-how-to-reduce-your-internet-bill-tsx": () => import("./../../../src/pages/thoughts/How-to-reduce-your-internet-bill.tsx" /* webpackChunkName: "component---src-pages-thoughts-how-to-reduce-your-internet-bill-tsx" */),
  "component---src-pages-thoughts-how-to-solve-wifi-issues-tsx": () => import("./../../../src/pages/thoughts/How-to-solve-wifi-issues.tsx" /* webpackChunkName: "component---src-pages-thoughts-how-to-solve-wifi-issues-tsx" */),
  "component---src-pages-thoughts-importance-of-competition-in-halifaxs-internet-market-tsx": () => import("./../../../src/pages/thoughts/Importance-of-competition-in-halifaxs-internet-market.tsx" /* webpackChunkName: "component---src-pages-thoughts-importance-of-competition-in-halifaxs-internet-market-tsx" */),
  "component---src-pages-thoughts-introducing-our-neighbor-in-need-program-tsx": () => import("./../../../src/pages/thoughts/Introducing-our-neighbor-in-need-program.tsx" /* webpackChunkName: "component---src-pages-thoughts-introducing-our-neighbor-in-need-program-tsx" */),
  "component---src-pages-thoughts-isp-provided-emails-tsx": () => import("./../../../src/pages/thoughts/ISP-provided-emails.tsx" /* webpackChunkName: "component---src-pages-thoughts-isp-provided-emails-tsx" */),
  "component---src-pages-thoughts-july-3-rds-service-interruption-tsx": () => import("./../../../src/pages/thoughts/July-3rds-service-interruption.tsx" /* webpackChunkName: "component---src-pages-thoughts-july-3-rds-service-interruption-tsx" */),
  "component---src-pages-thoughts-love-our-internet-or-its-free-tsx": () => import("./../../../src/pages/thoughts/Love-our-internet-or-its-free.tsx" /* webpackChunkName: "component---src-pages-thoughts-love-our-internet-or-its-free-tsx" */),
  "component---src-pages-thoughts-moving-made-easy-tsx": () => import("./../../../src/pages/thoughts/Moving-Made-Easy.tsx" /* webpackChunkName: "component---src-pages-thoughts-moving-made-easy-tsx" */),
  "component---src-pages-thoughts-peacetime-vs-wartime-ceo-tsx": () => import("./../../../src/pages/thoughts/Peacetime-vs-wartime-ceo.tsx" /* webpackChunkName: "component---src-pages-thoughts-peacetime-vs-wartime-ceo-tsx" */),
  "component---src-pages-thoughts-purple-cow-internet-expands-its-services-to-prince-edward-island-and-newfoundland-tsx": () => import("./../../../src/pages/thoughts/Purple-Cow-Internet-Expands-its-Services-to-Prince-Edward-Island-and-Newfoundland.tsx" /* webpackChunkName: "component---src-pages-thoughts-purple-cow-internet-expands-its-services-to-prince-edward-island-and-newfoundland-tsx" */),
  "component---src-pages-thoughts-purple-cow-internet-introduces-home-phone-tsx": () => import("./../../../src/pages/thoughts/Purple-Cow-Internet-Introduces-Home-Phone.tsx" /* webpackChunkName: "component---src-pages-thoughts-purple-cow-internet-introduces-home-phone-tsx" */),
  "component---src-pages-thoughts-purple-cow-internet-launches-tv-solution-tsx": () => import("./../../../src/pages/thoughts/Purple-Cow-Internet-Launches-TV-Solution.tsx" /* webpackChunkName: "component---src-pages-thoughts-purple-cow-internet-launches-tv-solution-tsx" */),
  "component---src-pages-thoughts-technicians-tsx": () => import("./../../../src/pages/thoughts/Technicians.tsx" /* webpackChunkName: "component---src-pages-thoughts-technicians-tsx" */),
  "component---src-pages-thoughts-the-ai-revolution-in-the-isp-sphere-tsx": () => import("./../../../src/pages/thoughts/The-AI-Revolution-in-the-ISP-Sphere.tsx" /* webpackChunkName: "component---src-pages-thoughts-the-ai-revolution-in-the-isp-sphere-tsx" */),
  "component---src-pages-thoughts-the-ideal-choice-for-newcomers-in-nova-scotia-prince-edward-island-and-newfoundland-tsx": () => import("./../../../src/pages/thoughts/The-Ideal-Choice-for-Newcomers-in-Nova-Scotia-Prince-Edward-Island-and-Newfoundland.tsx" /* webpackChunkName: "component---src-pages-thoughts-the-ideal-choice-for-newcomers-in-nova-scotia-prince-edward-island-and-newfoundland-tsx" */),
  "component---src-pages-thoughts-the-price-of-connectivity-how-canada-compares-globally-tsx": () => import("./../../../src/pages/thoughts/The-Price-of-Connectivity-How-Canada-Compares-Globally.tsx" /* webpackChunkName: "component---src-pages-thoughts-the-price-of-connectivity-how-canada-compares-globally-tsx" */),
  "component---src-pages-thoughts-tsx": () => import("./../../../src/pages/thoughts.tsx" /* webpackChunkName: "component---src-pages-thoughts-tsx" */),
  "component---src-pages-thoughts-wanderers-partnership-with-purple-cow-internet-tsx": () => import("./../../../src/pages/thoughts/Wanderers-Partnership-with-Purple-Cow-Internet.tsx" /* webpackChunkName: "component---src-pages-thoughts-wanderers-partnership-with-purple-cow-internet-tsx" */),
  "component---src-pages-thoughts-what-is-wifi-6-tsx": () => import("./../../../src/pages/thoughts/What-is-wifi-6.tsx" /* webpackChunkName: "component---src-pages-thoughts-what-is-wifi-6-tsx" */),
  "component---src-pages-thoughts-why-building-owners-encourage-certain-internet-service-providers-over-others-tsx": () => import("./../../../src/pages/thoughts/Why-Building-Owners-Encourage-Certain-Internet-Service-Providers-Over-Others.tsx" /* webpackChunkName: "component---src-pages-thoughts-why-building-owners-encourage-certain-internet-service-providers-over-others-tsx" */),
  "component---src-pages-thoughts-why-purple-cow-has-the-best-team-tsx": () => import("./../../../src/pages/thoughts/Why-Purple-Cow-Has_the_best_team.tsx" /* webpackChunkName: "component---src-pages-thoughts-why-purple-cow-has-the-best-team-tsx" */),
  "component---src-pages-thoughts-why-purple-cow-internet-is-the-best-internet-service-provider-in-nova-scotia-tsx": () => import("./../../../src/pages/thoughts/Why-Purple-Cow-Internet-is-the-best-internet-service-provider-in-Nova-Scotia.tsx" /* webpackChunkName: "component---src-pages-thoughts-why-purple-cow-internet-is-the-best-internet-service-provider-in-nova-scotia-tsx" */),
  "component---src-pages-thoughts-why-purple-cow-internet-tsx": () => import("./../../../src/pages/thoughts/Why-Purple-Cow-Internet.tsx" /* webpackChunkName: "component---src-pages-thoughts-why-purple-cow-internet-tsx" */),
  "component---src-pages-thoughts-why-purple-cows-the-best-tsx": () => import("./../../../src/pages/thoughts/Why-Purple-Cow's-the-Best.tsx" /* webpackChunkName: "component---src-pages-thoughts-why-purple-cows-the-best-tsx" */),
  "component---src-pages-tv-channel-guide-tsx": () => import("./../../../src/pages/tv/channel-guide.tsx" /* webpackChunkName: "component---src-pages-tv-channel-guide-tsx" */),
  "component---src-pages-tv-package-guide-tsx": () => import("./../../../src/pages/tv/package-guide.tsx" /* webpackChunkName: "component---src-pages-tv-package-guide-tsx" */),
  "component---src-pages-tv-privacy-policy-tsx": () => import("./../../../src/pages/tv/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-tv-privacy-policy-tsx" */),
  "component---src-pages-tv-setup-tsx": () => import("./../../../src/pages/tv/setup.tsx" /* webpackChunkName: "component---src-pages-tv-setup-tsx" */),
  "component---src-pages-tv-tsx": () => import("./../../../src/pages/tv.tsx" /* webpackChunkName: "component---src-pages-tv-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

