import React from "react"
import LayoutNew from "../components/LayoutNew"
import { Helmet } from "react-helmet"

export default function ServiceInterruptions() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Service Interruptions</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>Service Interruptions</h1>

        <h2>June 15th 2024</h2>
        <p class="font-bold">8:10AM</p>
        <p>
        I wanted to let you know that we experienced an issue in our network this morning effected by maintenance up stream. The issue initially started around 2:00 am, was resolved by 3:30 am, but resurfaced at 6:00 am. Our team has solved the issue and everyone should be back online without issues. Thank you so much for being in the herd!<br/><br/>Bradley Farquhar
<br/><br/>CEO
        </p>
    
      </section>
    </LayoutNew>
  )
}