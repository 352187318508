import React, { useState, useEffect } from 'react';
import '../styles/custome.css';
import { lambdaUrl } from "../config"
import { formatPhoneNumber } from "../utils"
import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import LayoutNew from "../components/LayoutNew";
import Loading from "../components/Loading"
import tvImg2 from "../../static/images/tv-2.png";


const SponsorshipsPage = () => {

  interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    subject: string;
    description: string;
  }

  const [formData, setFormData] = useState<FormData>({
    "firstName": '',
    "lastName": '',
    "email": '',
    "phone": '',
    "subject": '',
    "description": '',
  });

  const clearFormData = () => {
    formData.firstName = '';
    formData.lastName = '';
    formData.email = '';
    formData.phone = '';
    formData.subject = '';
    formData.description = '';
  }

  const [phoneError, setPhoneError]: any = useState("");
  const [currError, setCurrError]: any = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    // Check local storage to maintain state across refreshes
    const submissionStatus = sessionStorage.getItem('formSubmitted');
    if (submissionStatus === 'true') {
      setIsSubmitted(true);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let pNumber=e.target.value.replace(/\D/g, "")
    setFormData({ ...formData, phone: formatPhoneNumber(pNumber) })
    let phoneValid = pNumber.match(/^(\+1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/);
    if (!phoneValid) {
      phoneValid = pNumber.match(/^(\+?1 ?)?\(?([0-9]{1})\)?[-. ]?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/);
    }
    if (!phoneValid) {
      setPhoneError('Please enter a valid phone number');
    } else {
      setPhoneError('');
      setCurrError('');
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    if (phoneError !== "") {
      setIsLoading(false);
      setCurrError(phoneError);
      return;
    }
    try {
      const payload = { ...formData };
      clearFormData()
      const response = await fetch(`${lambdaUrl}submit-sponsorship`, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      setIsLoading(false);
      if (!response.ok) {
        setCurrError('Please try again later.');
      } else {
        setCurrError('');
        setIsSubmitted(true);
        sessionStorage.setItem('formSubmitted', 'true');
      }
    } catch (error) {
      setIsLoading(false);
      setCurrError('Please try again later.');
    }
  };

  if (isSubmitted) {
    return (
      <LayoutNew>
        <Helmet>
          <title>Form submitted – Purple Cow Internet 💜🐄</title>
        </Helmet>
        <div className="main-sponsorships-container">
          <div className="contact-form-container">
            <div className="text-center logo-center">
              <Link to="/">
                <StaticImage
                  className="w-24"
                  layout="constrained"
                  src="../content/cow.png"
                  alt="Purple Cow Internet 💜🐄"
                />
              </Link>
            </div>
            <h2>Form Submitted!</h2>
            <h4>Thank you for your submission. We will be in touch shortly.</h4>
          </div>
        </div>
      </LayoutNew>
    )
  }

  if (isLoading) {
    return <div
      style={{
        display: 'flex',
        justifyContent: 'center',  // Center horizontally
        alignItems: 'center',      // Center vertically
        height: '100vh'            // Full viewport height
      }}
    >
      <Helmet>
        <title>Form submitted – Purple Cow Internet 💜🐄</title>
      </Helmet>
      <Loading></Loading>
    </div>
  }

  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Sponsorship – Purple Cow Internet 💜🐄</title>
      </Helmet>
      <div className="main-sponsorships-container">
        <div className="contact-form-container">
          <div className="text-center logo-center">
            <Link to="/">
              <StaticImage
                className="w-24"
                layout="constrained"
                src="../content/cow.png"
                alt="Purple Cow Internet 💜🐄"
              />
            </Link>
          </div>
          <h2>Sponsorship Form</h2>
          <form id={"sponsorship-form"} onSubmit={handleSubmit}>
            <div className="mb-3 province-field">
              <label>First Name *: </label>
              <input type="text" name="firstName" placeholder="First name" value={formData?.firstName} required
                     onChange={handleChange} />
            </div>
            <div className="mb-3 postal-field">
              <label>Last Name *: </label>
              <input type="text" name="lastName" placeholder="Last name" value={formData?.lastName} required
                     onChange={handleChange} />
            </div>
            <div>
              <label>Email *: </label>
              <input type="email" name="email" placeholder="Email" value={formData?.email} required
                     onChange={handleChange} />
            </div>
            <div>
              <label>Phone *: </label>
              <span className="error-field">{phoneError}</span>
              <input type="tel" name="phone" placeholder="Cellular/Mobile Number" value={formData?.phone} required
                     maxLength={16}
                     pattern="^(\+1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})"
                     onChange={handlePhoneNumberChange} />
            </div>
            <div>
              <label>Subject *: </label>
              <input type="text" name="subject" placeholder="Subject" value={formData?.subject} required
                     onChange={handleChange} />
            </div>
            <div>
              <label>Description *: </label>
              <textarea name="description" placeholder="Description" value={formData?.description} required
                        onChange={handleChange}></textarea>
            </div>
            <div className="steps-bottom-row m-auto address-step-form-bottom">
              <div className="button-box step-btn-right">
                <span className="error-field">{currError}</span>

                <button className="btn bg-primary-700 text-white" id="submit_address">
                  {" "}
                  Submit Form{" "}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutNew>
  )
}

// Render the webpage
export default SponsorshipsPage
