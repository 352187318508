import React, {useEffect, useState} from "react";
import { Link } from "gatsby";
import LayoutNew from "../components/LayoutNew";
import { Helmet } from "react-helmet";
import HaveQuestion from "./new-pages/common/HaveQuestion";
import wifirouter from "../../static/images/wifi-router.png";
import easytoswitch from "../../static/images/easy-to-switch.png";
import superIcon1 from "../../static/images/tv-3.jpg";
import tripleplay from "../../static/images/tripleplay.png";
import LastSection from "./new-pages/common/LastSection";
import MessageModal from "./new-pages/common/MessageModal";
import bubbleIcon from "../../static/images/speech-bubble.png";
import cowRightBottom from "../../static/images/home-cow.png";
import mapImg from "../../static/images/map.png";
import arrowRight from "../../static/images/arrow.png";
import { transformReferralCodeToName } from "../customfunction";
import RatingReviewHome from "./new-pages/common/RatingReviewHome";
import CommonQuestionHome from "./new-pages/common/CommonQuestionHome";
import { hideCouponHeader, useReferralState, bannerMessage } from "../ReferralWrapper"
import { useSiteMetadata } from "../hooks";
import axios from "axios";
import {splitDescription} from "../utils";
import {productCatalogS3Bucket} from "../config";
import ServiceAddress from "./new-pages/common/ServiceAddress";

const FrontHomePage = () => {
    const [tabType, setTabType] = React.useState(false);
    const [messagePopup, setMessagePopup] = React.useState(false);
    let referral = useReferralState()
    let banner_message = bannerMessage();
    let isServiceOutageMode = useSiteMetadata()
    const [internetPlans, setInternetPlans] = useState([]);
    const [tvPlans, setTvPlans] = useState([]);
    const [phonePlans, setPhonePlans] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const internetResponse = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/internet/plans.json"
                );
                const tvResponse = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/tv/plans.json"
                );
                const phoneResponse = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/home-phones/plans.json"
                );
                setInternetPlans(internetResponse.data);
                setTvPlans(tvResponse.data);
                setPhonePlans(phoneResponse.data)
            } catch (err) {
                console.error("Failed to fetch internet plans:", err);
            }
        };
        fetchData();
    }, [])
    return (
        <div className="home-parent">
            <LayoutNew>
                <Helmet>
                    <title>Home – Purple Cow Internet 💜🐄</title>
                </Helmet>
                {/* {isServiceOutageMode && (
                <div className="top-0 px-4 py-3 text-lg font-thick tracking-widest text-center text-white bg-primary-700">
                    <Link to ="/" className="text-white block">We're aware of network interruptions currently affecting some of our customers. Our team is working on resolving this ASAP. Thank you for your patience. <br /> </Link>
                </div>
                )} */}
                {referral && referral.length > 0 && !hideCouponHeader(referral) && (
                    <div className="top-0 px-4 py-3 text-lg font-thin tracking-widest text-center text-white bg-primary-700">
                        {banner_message ? (<>{banner_message}</>) : <>
                            Hey, I see you're friends with{" "}
                            <span className="font-normal">
                                {" " + transformReferralCodeToName(referral)}
                            </span>
                            . Awesome!
                        </>}
                    </div>
                )}
                <section className="home-section1">
                    <div className="container mx-auto px-4">
                        <div className="relative">
                            <h2 className="h2 text-white">Easy to switch, reliable internet, only $60</h2>
                            <p className="p2 text-white my-7">Love our Internet or it's Free! Guaranteed!</p>
                            <div className="home-top-feild">
                                <input
                                    type="text"
                                    className="rounded-lg  focus:z-10 input1"
                                    name="line11"
                                    required
                                    placeholder="Service Address"
                                    id="home_new_address"
                                    onBlur={(e) => {
                                        setTimeout(() => {
                                            let ad: any = document.getElementById('address')
                                            if (ad !== null) {
                                                ad.value = '';
                                            }
                                            let unitField: any = document.getElementById('unitField')
                                            if (unitField !== null) {
                                                unitField.value = '';
                                            }
                                        }, 700)
                                    }}
                                />
                                <input
                                    type="text"
                                    className="rounded-lg  focus:z-10 input2 max-width80"
                                    name="line2"
                                    id="line2_address"
                                    placeholder={"Unit"}
                                    required
                                />
                            </div>
                            <Link className="btn-bg-new mt-5" onClick={(e) => {
                                let ad: any = document.getElementById('home_new_address');
                                if (!ad.value) {
                                    e.preventDefault()
                                }
                            }} to="/join-the-herd">Check Address <img src={arrowRight} alt="...." className="right-arrow" /></Link>
                        </div>
                        <img src={cowRightBottom} alt="cow" className="cow-right-bottom" />
                    </div>
                </section>

                <section className="tv-section4">
                    <div className="container mx-auto px-4">
                        <h2 className="h2 text-secondary text-center">Simple, honest pricing</h2>
                        <p className="p2 text-black text-center mb-8">And it doesn't go up after a year!</p>
                        <div className="selectPlan addon-plan-1">
                            {internetPlans.filter(obj => obj.status === "ACTIVE").map((plan, index) => (
                                index != 0 ? null : (
                                    <div className="selectPlan__optional">
                                        <label className="selectPlan__box phone-page">
                                            <input
                                                type="checkbox"
                                                name="selectPlan__"
                                                checked={false}
                                            />
                                            <div className="selectPlan__box-inner">
                                                <h3 className="h3">Internet</h3>
                                                <h2 className="h2"
                                                    dangerouslySetInnerHTML={{__html: '$' + internetPlans[0].billing[0].monthly.price + ' <sup>/ month</sup>'}}>
                                                </h2>
                                                <ul className="plan-list">
                                                    {splitDescription(internetPlans[0].description).map((line, index) => (
                                                        <li key={index}>{line}</li>
                                                    ))}
                                                </ul>
                                                <Link to="/internet">
                                                    <span className="a-link-bold">
                                                        View Internet options
                                                    </span>
                                                </Link>
                                            </div>
                                        </label>
                                    </div>
                                )
                            ))}
                            {tvPlans.filter(obj => obj.status === "ACTIVE").map((plan, index) => (
                                index != 0 ? null : (
                                    <div className="selectPlan__optional">
                                        <label className="selectPlan__box phone-page">
                                            <input
                                                type="checkbox"
                                                name="selectPlan__"
                                                checked={false}
                                            />
                                            <div className="selectPlan__box-inner">
                                                <h3 className="h3">TV</h3>
                                                <h2 className="h2"
                                                    dangerouslySetInnerHTML={{__html: '$' + tvPlans[0].billing_period[0].monthly.price + ' <sup>/ month</sup>'}}>
                                                </h2>
                                                <div className="chennels-box">
                                                    {tvPlans[0].featured_channels.map((channel) => ( /* slice(0, 6) gets the first 6 elements*/
                                                        <img src={channel.image_url} alt="channels" className="channel-icon"/>
                                                    ))}
                                                </div>

                                                <p className="p4">{tvPlans[0].description}</p>
                                                <Link to="/tv">
                                                <span className="a-link-bold">
                                                    View TV options
                                                </span>
                                                </Link>
                                            </div>
                                        </label>
                                    </div>
                                )
                            ))}
                            {phonePlans.filter(obj => obj.status === "ACTIVE").map((plan, index) => (
                                index != 0 ? null : (
                                    <div className="selectPlan__optional">
                                        <label className="selectPlan__box phone-page">
                                            <input
                                                type="checkbox"
                                                name="selectPlan__"
                                                checked={false}
                                            />
                                            <div className="selectPlan__box-inner">
                                                <h3 className="h3">Home Phone</h3>
                                                <h2 className="h2"
                                                    dangerouslySetInnerHTML={{__html: '$' + phonePlans[0].billing_period[0].monthly.price + ' <sup>/ month</sup>'}}>
                                                </h2>
                                                <ul className="plan-list">
                                                    {splitDescription(phonePlans[0].description).map((line, index) => (
                                                        <li key={index}>{line}</li>
                                                    ))}
                                                </ul>
                                                <Link to="/home-phone">
                                                <span className="a-link-bold">
                                                    View Phone options
                                                </span>
                                                </Link>
                                            </div>
                                        </label>
                                    </div>
                                )
                            ))}
                                </div>
                                </div>
                                </section>
        <section className="tv-section9">
            <div className="container mx-auto px-4">
            <h2 className="h2 text-secondary text-center">Awesome, reliable Wi-Fi</h2>
            <p className="p2 text-black text-center mb-8">Wi-Fi and modem is included for Free! Not like the other guys! </p>
                <div className="max-width1000">
                    <div className="grid  sm:grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="">
                            <img src={wifirouter} alt="TV" className="" />
                        </div>
                        <div className="section9-text">
                            <h3 className="h3">The incredibly smart Wi-Fi modem</h3>
                            <p className="p2">Our Wi-Fi modem, which also plays the role of a router, will impress you with its amazing technical capabilities. Its band-steering function, for example, makes for smooth web surfing on any device.</p>
                            <p className="p2">With only one box to plug in, you’ll have a clean, streamlined look that’s perfect for placing this router in any room of the home.</p>
                            <Link className="btn-bg-new mx-auto mt-8"  to="/join-the-herd">Check my eligibility</Link>

                        </div>

                    </div>
                </div>
            </div>
        </section> 
        <section className="internet-section2">
                <div className="container mx-auto px-4 bg-transparent">
                    <h2 className="h2">Want TV and Phone? We have that!</h2>
                    <p className="p2 text-center">Enhance your connection with Purple Cow’s TV and Home Phone! With our TV app, you can stream your favorite shows on any Apple, or Firestick device—start, stop, pause, and record in the cloud with ease. Our Home Phone service keeps you connected with crystal-clear calling featuring unlimited North American long distance, call display, call waiting, and more. Simple, reliable, and built for the herd!</p>
                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-4">
                        <div className="relative">
                            <div className="">
                                <img src={tripleplay} alt="guaranteed" className="center" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        <section className="tv-section9">
            <div className="container mx-auto px-4">
            <h2 className="h2 text-secondary text-center">Super easy to switch!</h2>
            <p className="p2 text-black text-center mb-8">Free tech visits if needed.</p>
                <div className="max-width1000">
                    <div className="grid  sm:grid-cols-1 md:grid-cols-2 gap-4">

                        <div className="section9-text">
                            <h3 className="h3">1. Signup online</h3>
                            <p className="p2">It only takes 30 seconds!</p>
                            <h3 className="h3">2. We'll mail you an all-in-one box.</h3>
                            <p className="p2">An all-in-one box designed to look great in any room.</p>
                            <h3 className="h3">3. Just plug it in!</h3>
                            <p className="p2">It's as simple as plugging in your laptop!</p>
                            <Link className="btn-bg-new mx-auto mt-8"  to="/join-the-herd">Join the herd</Link>

                        </div>
                        <div className="">
                            <img src={easytoswitch} alt="TV" className="tv-sec9-img" />
                        </div>

                    </div>
                </div>
            </div>
        </section> 
                <ServiceAddress />
                <RatingReviewHome
                    title="We are the highest rated internet provider in the Atlantic Provinces"
                    subtitle="nothing wrong bragging a little :)"
                />

                <section className="home-section4">
                    <div className="container mx-auto px-4">
                        <div className="max-width1000 grid grid-cols-1 md:grid-cols-2 gap-4">
                            <img src={mapImg} alt="map" className="map-img" />
                            <div className="home-sec4-content">
                                <h3 className="h3">Purple Cow's service is in Nova Scotia, Prince Edward Island & Newfoundland</h3>
                                <p className="p2">We cover the majority of the above provinces. If you have any questions on serviceablity please check your address here online or shoot us a message.</p>
                                <Link className="btn-bg-new mx-auto mt-8"  to="/join-the-herd">Check my eligibility</Link>
                            </div>
                        </div>
                    </div>
                </section>

                <CommonQuestionHome />
                <HaveQuestion closepopup={setMessagePopup} />

                <div
                    dangerouslySetInnerHTML={{
                        __html: '<!-- TODO: hide this.. https://www.hidden-treasure.purplecowinternet.com -->'
                    }}
                />

                <LastSection title="Final note. We think you're awesome" />

                <div className="contact-us-parent">
                    <div
                        className="contact-click-box"
                        onClick={(e) => {
                            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                            if (isMobile) {
                                e.preventDefault(); // Prevent the default anchor click behavior
                                window.location.href = "sms:1-902-800-2660?body=Hey%20Purple%20Cow%20I%20have%20a%20question.%20";
                            } else {
                                // Open the existing popup on other devices
                                setMessagePopup(true);
                            }
                        }}
                    >
                        <img src={bubbleIcon} alt="bubble" className="bubble-icon" />
                    </div>
                </div>
                {messagePopup ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
            </LayoutNew>
        </div>
    );
};

export default FrontHomePage;