import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>2024: A Year of Growth, Connection, and Innovation 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
       
        
        <StaticImage
          layout="constrained"
          src="images/2024recap.png"
          alt="Purple Cow Internet - 2024 recap"
          /> 

    
        <p>As 2024 comes to a close, we’re taking a moment to reflect on the super cool azz steps we’ve made together as a herd. This year has been nothing short of transformative, and it’s all thanks to your unwavering support and belief in Purple Cow Internet.</p>

        <p>Our mission remains steadfast: to drive down the cost of internet while delivering unmatched quality and service. With your help, we’ve continued to challenge the big guys and create real change in the internet industry.</p>

        <h2>Here’s what we accomplished in 2024:</h2>
        <ul>
            <li><strong>Company Growth:</strong> This year, the herd grew by 25%! It’s inspiring to see so many Maritimers joining us in the fight against high internet prices.</li>
            <li><strong>Launching the Neighbor in Need Program:</strong> With 150 herd members contributing monthly, this program is already making a meaningful impact by supporting fellow customers facing tough times.</li>
            <li><strong>Building a Stronger Team:</strong> We welcomed amazing new leaders to our team, including a new Operations Manager and Customer Service Manager, who are helping us take our service to the next level.</li>
            <li><strong>A Fresh Look:</strong> We refreshed our branding with a new logo and an even cooler cow! It’s a bold new look that reflects the energy and spirit of Purple Cow.</li>
            <li><strong>Stronger Networks:</strong> Our internal network became even more robust with added redundancy. Twice this year, other providers experienced outages while Purple Cow kept our herd online without interruption.</li>
            <li><strong>Love Our Internet or It’s Free Guarantee:</strong> We launched a new guarantee—if you don’t love our internet in your first month, we’ll refund you 100%. It’s all part of our commitment to putting you first.</li>
        </ul>

        <h2>Looking Ahead to 2025</h2>
        <p>The coming year promises even more exciting developments. Our goal is to grow the herd by another 25% through new product offerings, expanded sales channels, and continued improvement from our incredible team. With these efforts, we’re confident that we’ll reach even more Maritimers and drive our mission further.</p>

        <p>Thank you for being a part of this journey. Your support fuels everything we do. Together, we’re not just changing the internet industry; we’re building a community that stands for better service, fair pricing, and innovation.</p>

        <p><strong>Here’s to an even better 2025!</strong></p>
    
            
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
